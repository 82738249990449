import React, { Suspense, useEffect, useState } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import { RequireAuth } from 'react-auth-kit'
import axios from 'axios';
import { useAuthUser, useSignIn, useSignOut } from 'react-auth-kit'
import ReactPixel from 'react-facebook-pixel';

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"
import posthog from "posthog-js";
import { useLocalStorage } from "./Functions/UseLocalStorage";
import CookiesNotice from "./Pages/Main/Components/CookieNotice";
import { lazyWithRetry } from "./Functions/lazyRetry";
import { PuffLoader } from "react-spinners";
import { getCookiesDict, mapURLToSalonId } from "./Pages/Main/System/CommonFunction";
import { JWT_LIFETIME, JWT_REFRESH_LIFETIME } from "./Functions/constants";
import { formatPolishDatetime } from "./Pages/Main/System/Functions";

// Websites
const MainPage = lazyWithRetry(() => import("./Pages/Main/MainPage"))
const Privacy = lazyWithRetry(() => import("./Pages/Main/Privacy"))
const Terms = lazyWithRetry(() => import("./Pages/Main/Terms"))
const PrivacyClause = lazyWithRetry(() => import("./Pages/Main/Websites/PrivacyClause"))

const DemoMain = lazyWithRetry(() => import("./Pages/Main/Websites/Demo/DemoMain"))
const BelloOcchioMain = lazyWithRetry(() => import("./Pages/Main/Websites/BelloOcchio/BelloOcchioMain"))
const BeautyByKMain = lazyWithRetry(() => import("./Pages/Main/Websites/BeautyByK/BeautyByKMain"))
const BeautyByKTraining = lazyWithRetry(() => import("./Pages/Main/Websites/BeautyByK/BeautyByKTraining"))
const ElianaMain = lazyWithRetry(() => import("./Pages/Main/Websites/Eliana/ElianaMain"))
const PruszkowskiBarberMain = lazyWithRetry(() => import("./Pages/Main/Websites/PruszkowskiBarber/PruszkowskiBarberMain"))
const JamesonBarberShopMain = lazyWithRetry(() => import("./Pages/Main/Websites/JamesonBarberShop/JamesonMain"))
const EtnoStudioMain = lazyWithRetry(() => import("./Pages/Main/Websites/EtnoStudio/EtnoStudioMain"))
const WDmain = lazyWithRetry(() => import("./Pages/Main/Websites/WiktoriaDyszkiewicz/WD_Main"))
const BarbershopClassicMain = lazyWithRetry(() => import("./Pages/Main/Websites/BarbershopClassic/BarberMain"))
const HairRoomMain = lazyWithRetry(() => import("./Pages/Main/Websites/HairRoom/HairRoomMain"))
const InkaMain = lazyWithRetry(() => import("./Pages/Main/Websites/InKa/InKaMain"))
const Studio7Main = lazyWithRetry(() => import("./Pages/Main/Websites/Studio7/Studio7Main"))
const NailsGMain = lazyWithRetry(() => import("./Pages/Main/Websites/NailsG/NailsGMain"))
const VenusMain = lazyWithRetry(() => import("./Pages/Main/Websites/Venus/VenusMain"))
const NailGalleryMain = lazyWithRetry(() => import("./Pages/Main/Websites/NailGallery/NailGalleryMain"))
const FryzjerskieInspiracjeMain = lazyWithRetry(() => import("./Pages/Main/Websites/FryzjerskieInspiracje/InspiracjeMain"))

// Pages for customer dashboard and booking visits
const VisitConfirm = lazyWithRetry(() => import("./Pages/Main/Websites/VisitConfirm"))
const ConfirmedPage = lazyWithRetry(() => import("./Pages/Main/Websites/ConfirmedPage"))
const CustomerLogin = lazyWithRetry(() => import("./Pages/Main/System/Customer/CustomerLogin"))
const CustomerRegister = lazyWithRetry(() => import("./Pages/Main/System/Customer/CustomerRegister"))
const CustomerActivation = lazyWithRetry(() => import("./Pages/Main/System/Customer/CustomerActivation"))
const PasswordReset = lazyWithRetry(() => import("./Pages/Main/System/Customer/PasswordReset"))
const PasswordResetConfirm = lazyWithRetry(() => import("./Pages/Main/System/Customer/PasswordResetConfirm"))
const CustomerDashboard = lazyWithRetry(() => import("./Pages/Main/System/Customer/CustomerDashboard"))
const VisitCard = lazyWithRetry(() => import("./Pages/Main/System/Customer/VisitCard"))
const CustomerLogOut = lazyWithRetry(() => import("./Pages/Main/System/Customer/Logout"))

// Deposit
const DepositRequired = lazyWithRetry(() => import("./Pages/Main/Websites/Deposit/DepositRequired"))
const DepositCancel = lazyWithRetry(() => import("./Pages/Main/Websites/Deposit/DepositCancel"))
const DepositSuccess = lazyWithRetry(() => import("./Pages/Main/Websites/Deposit/DepositSuccess"))

const PageClosed = lazyWithRetry(() => import("./Pages/Main/Websites/PageClosed"))

// Pages for BASIC users
const Basic = lazyWithRetry(() => import("./Pages/Main/System/Basic/Basic"))
const NewSalon = lazyWithRetry(() => import("./Pages/Main/System/Basic/NewSalon"))

// User actions
const Login = lazyWithRetry(() => import("./Pages/Main/System/UserActions/Login"))
const Register = lazyWithRetry(() => import("./Pages/Main/System/UserActions/Register"))
const Activation = lazyWithRetry(() => import("./Pages/Main/System/UserActions/Activation"))
const PwdReset = lazyWithRetry(() => import("./Pages/Main/System/UserActions/PwdReset"))
const PwdResetConfirm = lazyWithRetry(() => import("./Pages/Main/System/UserActions/PwdResetConfirm"))

// Account settings
const Account = lazyWithRetry(() => import("./Pages/Main/System/Account"))
const LogOut = lazyWithRetry(() => import("./Pages/Main/System/UserActions/Logout"))

// Salon settings, invites and statistics
const Config = lazyWithRetry(() => import("./Pages/Main/System/Salon/Config"))
const BulkDeleteVisits = lazyWithRetry(() => import("./Pages/Main/System/Salon/BulkDeleteVisits"))
const General = lazyWithRetry(() => import("./Pages/Main/System/Salon/General"))
const Employees = lazyWithRetry(() => import("./Pages/Main/System/Salon/Employees"))
const Statistics = lazyWithRetry(() => import("./Pages/Main/System/Salon/Statistics"))
const Invite = lazyWithRetry(() => import("./Pages/Main/System/Salon/Invite"))
const AcceptInvite = lazyWithRetry(() => import("./Pages/Main/System/Salon/AcceptInvite"))

// Visits & TimeOff
const VisitList = lazyWithRetry(() => import("./Pages/Main/System/Visits/VisitList"))
const Visit = lazyWithRetry(() => import("./Pages/Main/System/Visits/Visit"))
const NewVisit = lazyWithRetry(() => import("./Pages/Main/System/Visits/NewVisit"))
const Schedule = lazyWithRetry(() => import("./Pages/Main/System/Visits/Schedule"))

const TimeOff = lazyWithRetry(() => import("./Pages/Main/System/Visits/TimeOff"))
const NewTimeOff = lazyWithRetry(() => import("./Pages/Main/System/Visits/NewTimeOff"))

// Clients
const ClientList = lazyWithRetry(() => import("./Pages/Main/System/Clients/ClientList"))
const Client = lazyWithRetry(() => import("./Pages/Main/System/Clients/Client"))
const NewClient = lazyWithRetry(() => import("./Pages/Main/System/Clients/NewClient"))

// Treatments
const TreatmentList = lazyWithRetry(() => import("./Pages/Main/System/Salon/Treatments/TreatmentList"))
const Treatment = lazyWithRetry(() => import("./Pages/Main/System/Salon/Treatments/Treatment"))
const NewTreatment = lazyWithRetry(() => import("./Pages/Main/System/Salon/Treatments/NewTreatment"))

// Resources
const ResourceList = lazyWithRetry(() => import("./Pages/Main/System/Salon/Resources/ResourceList"))
const Resource = lazyWithRetry(() => import("./Pages/Main/System/Salon/Resources/Resource"))
const NewResource = lazyWithRetry(() => import("./Pages/Main/System/Salon/Resources/NewResource"))


function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRefreshOngoing, setIsRefreshOngoing] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const [cookieConsent, setCookieConsent] = useLocalStorage('cookieConsent', false)
  const location = useLocation();
  const signIn = useSignIn()
  const signOut = useSignOut()
  const auth_state = useAuthUser()

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  // PostHog and Pixel
  useEffect(() => {
    if (!window.location.host.includes('192.168.1.26') && !window.location.host.includes('localhost')) {
        // Init Facebook Pixel
        ReactPixel.init(process.env.REACT_APP_PIXEL_ID, {}, {autoConfig: false, debug: false, allowDuplicatePageViews: false})
        console.log("Pixel initialized")

        // Init posthog
        posthog.init('phc_4A717UbOQ3SgUX6kcp9iMRDAIoFu7xMfxybs2jAwAFS', {
            persistence: cookieConsent ? "cookie" : "memory",
            api_host:'https://eu.posthog.com', 
            capture_pageview: false, // To avoid double page views as they are called manually in location useEffect below
            session_recording: {maskInputOptions: {date: false, password: true}, maskAllInputs: false}
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookieConsent])

  useEffect(() => {
    const salonUUID = mapURLToSalonId(location.pathname)  // If not found it's gonna be "/"
    let properties = salonUUID ? { salon_uuid: salonUUID } : {}
    posthog.capture("$pageview", properties)

    // Check if JWT refresh is needed and call it in that case
    if (!isRefreshOngoing) {
        setTimeout(checkJWT, 1000)
        // checkJWT()
    }

    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  // Pageleave
  useEffect(() => {
    const handleBeforeUnload = () => {
        posthog.capture("$pageleave")
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove listener after component unmounte or update
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);


    const refreshToken = async (token) => {
        // Token refresh
        try {
            console.log("Running custom JWT Refresh...")
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/jwt/refresh/`, {'refresh': token})

            // log in
            let authState = {
                email: auth_state().email, 
                user_id: auth_state().user_id, 
                salon_id: auth_state().salon_id, 
                role: auth_state().role,
                first_name: auth_state().first_name,
                last_name: auth_state().last_name,
            }
            if (auth_state().phone_number) {
                authState["phone_number"] = auth_state().phone_number
            }
            signOut()
            signIn({
                token: response.data.access,
                expiresIn: JWT_LIFETIME, 
                refreshToken: token,
                tokenType: "Bearer",
                refreshTokenExpireIn: JWT_REFRESH_LIFETIME,
                authState: authState
            })
            posthog.group("company", auth_state().salon_id)
            posthog.identify(authState["phone_number"] ?? auth_state().email)
            console.log("Success!")
        }
        catch (error) {
            console.log("Fail!")
            console.error(error)
            signOut()
        }  
    }

    const checkJWT = async () => {
        const delay = 55  // refresh interval in minutes
        const authState = await auth_state()
        const cookies = await getCookiesDict()
        console.log(cookies)
        // console.log(await document.cookie) // somehow this makes the cookies load after login so that they aren't null - magic!
        
        let lastRefreshTime = cookies["_auth_refresh_time"]  // End of current lifetime - can be used to calculate last refresh
        const token = cookies["_auth_refresh"]
        
        // --------------------- DEBUG ONLY ---------------------
        const authCookiesDEBUG = {
            _auth_refresh_time: lastRefreshTime,
            _auth_refresh: token,
            _auth: cookies["_auth"],
            _auth_state: cookies["_auth_state"],
            _auth_storage: cookies["_auth_storage"],
            _auth_type: cookies["_auth_type"],
        }
        console.log(`AuthCookies: ${JSON.stringify(authCookiesDEBUG)}\n\nAuthState: ${JSON.stringify(authState)}`)
        // --------------------- DEBUG ONLY ---------------------

        if (!lastRefreshTime || !token) {
            console.log(`No: ${!token ? "_auth_refresh" : ""} | ${!lastRefreshTime ? "_auth_refresh_time" : ""} - skipping refresh check and signing out`)
            if (authState !== null) {
                console.log("Signing out...")
                signOut()
            }
            return
        }

        const now = new Date()
        lastRefreshTime = new Date(lastRefreshTime)
        // life time is 24h so we need to subtract it to get the last refresh time
        lastRefreshTime.setHours(lastRefreshTime.getHours() - 24) 

        console.log(
            "JWT refresh:", formatPolishDatetime(lastRefreshTime),
            "| Time passed:", ((now - lastRefreshTime) / 1000 /60).toFixed(2), "[min]"
        )
    
        // Check if the interval was missed
        if (!lastRefreshTime || (now - lastRefreshTime) > delay * 60 * 1000) {
            setIsRefreshOngoing(true)
            await refreshToken(token)
            setIsRefreshOngoing(false)
        }
    }

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main>
            <ScrollToTopButton />
            {cookieConsent ? null : (
              <CookiesNotice acceptCookies={setCookieConsent} />
            )}
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <PuffLoader color="#36d7b7" />
                </div>
            }>
                <Routes>
                  <Route path="/" element={<MainPage style={{ "--base-color": "#0038e3" }} cookieConsent={cookieConsent}/>} />
                  <Route path="/privacy" element={<Privacy cookieConsent={cookieConsent} setCookieConsent={setCookieConsent}/>} />
                  <Route path="/terms" element={<Terms/>} />
                  <Route path="/privacy_clause/:uuid" element={<PrivacyClause/>} />

                  <Route path="barberrosa" element={<PageClosed/>} />
                  <Route path="hair_by_jowita" element={<PageClosed/>} />
                  <Route path="barbershopClassic" element={<BarbershopClassicMain/>} />
                  <Route path="hairroom" element={<HairRoomMain/>} />
                  <Route path="inka" element={<InkaMain/>} />
                  <Route path="studio7" element={<Studio7Main/>} />
                  <Route path="nails-g" element={<NailsGMain/>} />
                  <Route path="venus" element={<VenusMain/>} />
                  <Route path="nailGallery" element={<NailGalleryMain/>} />
                  <Route path="fryzjerskie_inspiracje" element={<FryzjerskieInspiracjeMain/>} />
                  <Route path="pruszkowski_barber" element={<PruszkowskiBarberMain/>} />
                  <Route path="jameson" element={<JamesonBarberShopMain/>} />
                  <Route path="etno_studio" element={<EtnoStudioMain/>} />
                  <Route path="wiktoria_dyszkiewicz" element={<WDmain/>} />
                  <Route path="belloOcchio" element={<BelloOcchioMain/>} />
                  <Route path="eliana" element={<ElianaMain/>} />
                  <Route path="beautyByK" element={<BeautyByKMain/>} />
                  <Route path="beautyByK/szkolenia" element={<BeautyByKTraining/>} />

                  <Route path="confirm" element={<VisitConfirm/>} />


                  {isRefreshOngoing ? 
                    <Route path=":salon_id/*" element={
                        <div className="d-flex justify-center" style={{ alignItems: 'center', height: '100vh' }}>
                            <div>
                                <p className="text-lg text-black font-serif">Trwa logowanie...</p>
                                <div className="d-flex justify-center">
                                    <PuffLoader color="#36d7b7" />
                                </div>
                            </div>
                        </div>
                    } />
                    :
                    <Route path=":salon_id">
                        <Route path="deposit_required" element={<DepositRequired/>} />
                        <Route path="deposit_cancel" element={<DepositCancel/>} />
                        <Route path="deposit_success" element={<DepositSuccess/>} />
                        <Route path="confirmed" element={<ConfirmedPage/>} />
                        <Route path="login" element={<CustomerLogin/>} />
                        <Route path="register" element={<CustomerRegister/>} />
                        <Route path="activate/:user_uuid" element={<CustomerActivation/>} />
                        <Route path="password_reset" element={<PasswordReset/>} />
                        <Route path="password_reset_confirm" element={<PasswordResetConfirm/>} />

                        {/* Auth is checked inside dashboard components in order to perform a redirect to the correct login url */}
                        <Route path="dashboard">
                            <Route path="" element={<CustomerDashboard />}/>
                            <Route path=":uuid" element={<VisitCard />}/>
                            <Route path="logout" element={<CustomerLogOut />}/>
                        </Route>
                    </Route>
                }

                  <Route path="demo">
                    <Route path="" element={<DemoMain cookieConsent={cookieConsent}/>} />
                  </Route>

                {/* Block rendering of system and thus sending API requets while JWT is refreshing */}
                {isRefreshOngoing ? 
                    <Route path="/system/*" element={
                        <div className="d-flex justify-center" style={{ alignItems: 'center', height: '100vh' }}>
                            <div>
                                <p className="text-lg text-black font-serif">Trwa logowanie...</p>
                                <div className="d-flex justify-center">
                                    <PuffLoader color="#36d7b7" />
                                </div>
                            </div>
                        </div>
                    } />
                    :
                    <Route path="/system">
                        <Route path="login" element={<Login cookieConsent={cookieConsent}/>} />
                        <Route path="register" element={<Register cookieConsent={cookieConsent}/>} />
                        <Route path="activation/:uuid/:token" element={<Activation cookieConsent={cookieConsent}/>} />

                        <Route path="account" element={ <RequireAuth loginPath={'/system/login'}> <Account /> </RequireAuth> }/>
                        <Route path="logout" element={ <RequireAuth loginPath={'/system/login'}> <LogOut /> </RequireAuth> }/>

                        <Route path="basic">
                        <Route path="" element={ <RequireAuth loginPath={'/system/login'}> <Basic /> </RequireAuth> }/>
                        <Route path="new_salon" element={ <RequireAuth loginPath={'/system/login'}> <NewSalon /> </RequireAuth> }/>
                        </Route>

                        <Route path="password-reset">
                        <Route path="" element={<PwdReset />} />
                        <Route path="confirm/:uuid/:token" element={<PwdResetConfirm />} />
                        </Route>

                        <Route path="visits">
                        <Route path="" element={ <RequireAuth loginPath={'/system/login'}> <VisitList /> </RequireAuth> }/>
                        <Route path=":uuid" element={ <RequireAuth loginPath={'/system/login'}> <Visit /> </RequireAuth> } />
                        <Route path="new" element={ <RequireAuth loginPath={'/system/login'}> <NewVisit /> </RequireAuth> } />
                        <Route path="schedule" element={ <RequireAuth loginPath={'/system/login'}> <Schedule /> </RequireAuth> } />

                        <Route path="timeoff">
                            <Route path=":uuid" element={ <RequireAuth loginPath={'/system/login'}> <TimeOff /> </RequireAuth> } />
                            <Route path="new" element={ <RequireAuth loginPath={'/system/login'}> <NewTimeOff /> </RequireAuth> } />
                        </Route>
                        </Route>

                        <Route path="clients">
                        <Route path="" element={ <RequireAuth loginPath={'/system/login'}> <ClientList /> </RequireAuth> }/>
                        <Route path=":uuid" element={ <RequireAuth loginPath={'/system/login'}> <Client /> </RequireAuth> } />
                        <Route path="new" element={ <RequireAuth loginPath={'/system/login'}> <NewClient /> </RequireAuth> } />
                        </Route>

                        <Route path="salon">
                        <Route path="" element={ <RequireAuth loginPath={'/system/login'}> <General /> </RequireAuth> }/>
                        <Route path="config" element={ <RequireAuth loginPath={'/system/login'}> <Config /> </RequireAuth> }/>
                        <Route path="bulk_delete" element={ <RequireAuth loginPath={'/system/login'}> <BulkDeleteVisits /> </RequireAuth> }/>
                        <Route path="employees" element={ <RequireAuth loginPath={'/system/login'}> <Employees /> </RequireAuth> }/>
                        <Route path="statistics" element={ <RequireAuth loginPath={'/system/login'}> <Statistics /> </RequireAuth> }/>
                        <Route path="invite" element={ <RequireAuth loginPath={'/system/login'}> <Invite /> </RequireAuth> }/>
                        <Route path="invite/accept/:token" element={ <RequireAuth loginPath={'/system/login'}> <AcceptInvite /> </RequireAuth> }/>

                        <Route path="treatments">
                            <Route path="" element={ <RequireAuth loginPath={'/system/login'}> <TreatmentList /> </RequireAuth> }/>
                            <Route path=":uuid" element={ <RequireAuth loginPath={'/system/login'}> <Treatment /> </RequireAuth> } />
                            <Route path="new" element={ <RequireAuth loginPath={'/system/login'}> <NewTreatment /> </RequireAuth> } />
                        </Route>

                        <Route path="resources">
                            <Route path="" element={ <RequireAuth loginPath={'/system/login'}> <ResourceList /> </RequireAuth> }/>
                            <Route path=":uuid" element={ <RequireAuth loginPath={'/system/login'}> <Resource /> </RequireAuth> } />
                            <Route path="new" element={ <RequireAuth loginPath={'/system/login'}> <NewResource /> </RequireAuth> } />
                        </Route>
                        </Route>
                    </Route>
                }
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;